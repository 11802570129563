<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <p>
          <span
            ><b>USER: </b>
            <span
              ><b>{{ this.currentUser }}</b></span
            ></span
          >
        </p>
      </v-col>

      <v-col cols="12" sm="6">
        <div class="float-right">
          <b-button-group>
            <b-button
              v-if="canpost === 'true'"
              @click="getAvailableFolders()"
              class="add-button"
              >Add Folder
            </b-button>
            <!-- <b-button @click="openTasks" class="tasks-button">Tasks</b-button> -->
            <!-- <b-button @click="openOffers" class="offers-button"
              >Offers</b-button
            > -->
            <b-button @click="openWorksheets" class="purple-button"
              >WorkSheets</b-button
            >
            <b-button @click="goBack()" class="back-button">Back </b-button>
          </b-button-group>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <p>
          <span
            ><h3>DEAL: {{ this.dealFolders[0].deal }}</h3></span
          >
        </p>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-2" v-for="folders in dealFolders" :key="folders.id">
        <!-- <v-img v-if="folders.file"
                    :src="folders.file"
                    contain
                    class="img-class"
                    @click="downloadFile(dealFolders.file,dealFolders.filename)">
                </v-img>
                <v-img v-if="!folders.file"
                    :src="require('../assets/Folder yellow vertical 2.png')"
                    contain
                    class="img-class"
                    @click="downloadFile(dealFolders.file,dealFolders.filename)">
                </v-img>                 -->
        <br />
        <p class="image_text" @click="openDealFolder(deals.id)">
          {{ folders.folder }}
        </p>
      </div>
    </div>

    <v-card>
      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card>
          <div class="">
            <div class="popup-wrap">
              <v-btn class="close-popup" text @click="close">
                <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
              </v-btn>

              <div class="popup-wrap-inner step-one">
                <div class="popup-title">
                  <h6>
                    {{ editedItem.id ? "Update Folder Record" : "Add Folder" }}
                  </h6>
                </div>
                <div class="customer-form">
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-container>
                      <v-row class="px-5">
                        <v-col cols="12" sm="12">
                          <div id="bsText">
                            <b-input-group size="sm">
                              <template #prepend size="sm">
                                <b-input-group-text size="sm"
                                  >Folder Name</b-input-group-text
                                >
                              </template>
                              <b-form-select
                                v-model="editedItem.folder_id"
                                :options="list_folders"
                                class="mb-3"
                                value-field="id"
                                text-field="text"
                                disabled-field="notEnabled"
                                :rules="[
                                  (v) => !!v || 'This field is required',
                                ]"
                              ></b-form-select>
                            </b-input-group>
                            <p></p>
                            <p v-if="!foldernameIsValid" class="err-message">
                              Please select a folder
                            </p>
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div class="text-right">
                            <v-btn
                              :disabled="!formIsValid"
                              color="primary"
                              dark
                              @click="saveFormData"
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";

export default {
  data() {
    return {
      componentURL: `DealFolders/?deal_id=${this.$route.params.id}`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isReporter: localStorage.getItem("Reporter"),
      teamAccess: localStorage.getItem("teamAccess"),
      currentUser:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      currentUserType: localStorage.getItem("role"),
      userid: localStorage.getItem("userid"),
      deal_id: `${this.$route.params.id}`,

      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "danger",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "danger",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,

      valid: false,
      dialog: false,
      dialogDelete: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",

      list_folders: [],

      dealFolders: {
        id: "",
        deal_id: "",
        deal: "",
        folder_id: "",
        folder: "",
        filename: "",
        file: "",
      },

      editedItem: {
        id: "",
        deal_id: `${this.$route.params.id}`,
        folder_id: "",
      },
    };
  },

  computed: {
    foldernameIsValid() {
      return !!this.editedItem.folder_id;
    },

    // adminuserIsValid () {
    // return !!this.editedItem.admin_user_id
    // },

    formIsValid() {
      return this.foldernameIsValid;
    },
  },

  created() {
    this.getDealFolders();
  },

  methods: {
    getDealFolders() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.currentUserType);
      console.log("Current Deal ID: ", this.$route.params.id);

      CommonServices.getList(this.componentURL)
        .then((res) => {
          console.log("Folders: ", res);
          this.dealFolders = res.results;
          console.log("Current Deal: ", this.dealFolders[0].deal);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAvailableFolders() {
      console.log("Deal ID: ", this.deal_id);
      CommonServices.putData("Functions", {
        id: 1,
        param_one_number: this.deal_id,
      })
        .then((response) => {
          console.log("Function Response: ", response.id);
          //The post to Function 1 above causes a refresh of the AvaliableFolders table;Get the list from this table
          CommonServices.getList(`AvailableFolders/?deal_id=${this.$route.params.id}`)
            .then((res) => {
              console.log("AvailableFolders: ", res);
              let codes = res.results;
              codes.map((code) =>
                this.list_folders.push({
                  id: code.folder_id,
                  text: code.folder,
                })
              );
              this.dialog = true;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log("putData Error: ", error);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openBlindAd() {
      this.$router.push(`/BlindAdWorksheet/${this.$route.params.id}`);
    },

    openWorksheets() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/WorkSheets/`);
    },

    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              console.log("Saved item: ", response);
              location.reload();
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
      }
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.$refs.form.reset();
      this.resetItems();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    resetItems() {
      console.log("Resetting items...");
      this.editedItem = {
        id: "",
        deal_id: "",
        folder_id: "",
      };
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}
.popup-wrap {
  padding: 40px 0;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}

.save-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
  height: 36px;
  width: 125px;
  margin-right: 8px;
}

.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blue-button {
  background-color: rgb(13, 13, 68);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(194, 88, 48, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}
.image_text {
  border: 1px solid black;
  margin: auto;
  padding: 12px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.img-class {
  height: 90px !important;
  width: 90px !important;
}

.float-right {
  float: right;
  padding: 12px;
}
</style>
